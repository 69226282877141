import { Routes } from "@angular/router";

export const appRoutes: Routes = [
    { path: 'info', loadChildren: () => import('./info/info.module').then(m => m.InfoModule) },
    { path: 'about', loadChildren: () => import('./about/about.module').then(m => m.AboutModule) },
    { path: 'contact', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule) },
    { path: 'software-project', loadChildren: () => import('./software-project/software-project.module').then(m => m.SoftwareProjectModule) },
    { path: 'start', loadChildren: () => import('./start/start.module').then(m => m.StartModule) },
    { path: '', redirectTo: '/start/home', pathMatch: 'full' },
];
